.container {
   width: 100%;
}

.section {
  width: 60%;
  padding-top: 5%;
  text-align: left;
  margin: 0 auto;
}

.heading {
   font-size: 3rem;
   margin-bottom: 10%;
}

.line {
   color: rgba(203, 56, 6, 0.8);
   font-size: 3rem;
 }
 .games {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

 }

 @media(max-width: 900px){
    .section {
       width: 80%;
    }
 }

 @media(max-width: 600px){
  .section {
     width: 90%;
  }
}

