.card {
  width: 47%;
  height: 50vh;
  margin-bottom: 6rem;
  animation-name: moveUp;
  animation-duration: 1s;
 
}

.image {
  width: 100%;
  height: 100%;
  position: relative;
  transition: all 1s;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  border-radius: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position:center;
  overflow: hidden;
}

.description {
   position: absolute;
   width: 100%;
   top: 0;
   left: 0;
   background-color: rgba(0, 0, 0, .8);
   z-index: -1;
   transform: translateY(-100%);
   transition: all .5s;
   padding: 5%;
   font-size: 2rem;

}

.bar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.8);
  align-items: baseline;
}

.title {
  font-size: 2.5rem;
}

.image:hover .description {
   z-index: 1;
   transform: translateY(0);
}

@keyframes moveUp {
  0% {
    opacity: 0.5;
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media(max-width: 900px){
  .card {
    height: 50vh;
  }
}


@media(max-width: 600px){
  .card {
    width: 100%;
  }
  
}



