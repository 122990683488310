@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Open+Sans:wght@400;700&display=swap');

* {
  box-sizing: border-box;
}

body {
  text-align: center;
  background-color: black;
  color: white;
  font-family: 'Open Sans', 'Lato', sans-serif;
  margin: 0;
}

html {
  font-size: 62.5%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato', sans-serif;
}

@media(max-width: 600px) {
  html {
    font-size: 50%;
  }
}

@media(max-width: 900px){
  html {
    font-size: 60%;
  }
}

